
import { useWindowProperties } from "@/utils/windowProperties";
import { PropType } from "vue";

interface ItemsProp {
  name?: string;
  color?: string;
  type?: string;
  id?: string;
  icon?: string;
  iconSize?: string;
  warn?: string;
  disabled?: boolean;
  nestContext?: string;
}

import { defineComponent } from "vue";
export default defineComponent({
  name: "ContextMenu",
  props: {
    pos: {
      type: Object as PropType<{ x?: number; y?: number }>,
      required: true,
    },
    element: {
      type: HTMLElement,
      required: false,
    },
    items: {
      type: Array as PropType<ItemsProp[]>,
      required: true,
    },
    type: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      height: 0,
      width: 0,
      mount: false,
      selection: window.getSelection(),
      currentHoveringItem: null as any,
    };
  },
  computed: {
    itemsWithExtras(): any {
      if (!this.element) return this.items;
      const clickedElementSelected =
        this.selection?.toString().length &&
        this.selection.containsNode(this.element, true);

      if (!clickedElementSelected) return this.items;
      const seperator: ItemsProp = {
        type: "seperator",
      };
      const copyItem: ItemsProp = {
        id: "_copy",
        name: "Copy",
        icon: "content_copy",
      };
      return [copyItem, seperator, ...this.items];
    },
    clampPos(): any {
      const top = this.pos.y || 0;
      const left = this.pos.x || 0;

      // prevent from going bottom of the screen
      const heightPos = this.height + top;
      const clampedTop =
        this.clamp(heightPos, 0, this.windowDiamentions.height) - this.height;
      // prevent from going right of the screen
      const widthPos = this.width + left;
      const clampedLeft =
        this.clamp(widthPos, 0, this.windowDiamentions.width) - this.width;

      return {
        top: clampedTop + "px",
        left: clampedLeft + "px",
      };
    },
    windowDiamentions(): any {
      const { resizeHeight, resizeWidth } = useWindowProperties();
      return {
        height: resizeHeight,
        width: resizeWidth,
      };
    },
  },
  mounted() {
    window.setTimeout(() => {
      this.mount = true;
    }, 10);
    this.height = this.$el.clientHeight;
    this.width = this.$el.clientWidth;
  },
  methods: {
    clickOutside(event: any) {
      if (!this.mount) return;
      if (event.target.closest(".context")) return;
      this.$emit("close");
    },
    itemClicked(item: ItemsProp) {
      if (item.type === "seperator") return;
      if (item.disabled) return;
      this.$emit("itemClick", item);
      if (item.nestContext) return;
      this.$emit("close");

      if (item.id === "_copy") {
        document.execCommand("copy");
      }
    },
    itemHover(item: any, event: any) {
      this.currentHoveringItem = item;
      window.setTimeout(() => {
        if (item.id !== this.currentHoveringItem?.id) return;
        if (item.type === "seperator") return;
        this.$emit("itemHover", {
          item,
          target: event.target.closest(".content"),
        });
      }, 200);
    },
    clamp(num: number, min: number, max: number) {
      return num <= min ? min : num >= max ? max : num;
    },
  },
});
